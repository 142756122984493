.UnderDevelopment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    width: 60vh;
    text-align: center;
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
    border-radius: 10px;
    border: 1px solid #000;
}

.UnderDevelopment h1 {
    font-size: 3em;
    color: #333;
    margin-bottom: 20px;
}

.UnderDevelopment p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 40px;
}

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Mobile optimization */
@media (max-width: 768px) {
    .UnderDevelopment {
        width: 80vw;  /* Make the container width responsive */
        height: auto; /* Adjust the height */
        padding: 20px;
    }

    .UnderDevelopment h1 {
        font-size: 2em; /* Reduce heading size for smaller screens */
    }

    .UnderDevelopment p {
        font-size: 1em; /* Adjust paragraph font size */
    }

    .loader {
        width: 80px;  /* Reduce the loader size */
        height: 80px;
        border-width: 10px; /* Make the border width smaller */
    }
}
