.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;  /* Full screen height */
    background-color: #f5f5f5;  /* Light background to contrast the text */
}

h1 {
    font-size: 2em;
    color: #333;
}

.dot {
    display: inline-block;
    font-size: 2em;
    animation: wave 1.2s infinite ease-in-out;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes wave {
    0%, 60%, 100% {
        transform: translateY(0);  /* Start and end at the same position */
    }
    30% {
        transform: translateY(-10px);  /* Move dots up */
    }
}
